import React, { useState, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { FaExpand, FaCompress, FaDownload, FaShareAlt } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const GlobalStyle = createGlobalStyle`
  body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow: hidden;
    background-color: #2c3e50;
    font-family: Arial, sans-serif;
  }
`;

const Quiz = ({ quiz, socket }) => {
  const { t } = useTranslation();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [results, setResults] = useState(null);

  useEffect(() => {
    if (socket) {
      socket.on('quizResults', setResults);
    }
    return () => {
      if (socket) socket.off('quizResults');
    };
  }, [socket]);

  const handleAnswer = (answer) => {
    const currentQuestion = quiz.questions[currentQuestionIndex];
    const newAnswers = { ...answers, [currentQuestion.id]: answer };
    setAnswers(newAnswers);

    if (socket) socket.emit('quizAnswer', { questionId: currentQuestion.id, answer });

    if (currentQuestionIndex < quiz.questions.length - 1) {
      setCurrentQuestionIndex(prev => prev + 1);
    } else {
      if (socket) socket.emit('quizComplete', newAnswers);
      setQuizCompleted(true);
      if (!socket) setResults(calculateLocalResults(newAnswers, quiz.questions));
    }
  };

  const calculateLocalResults = (userAnswers, questions) => {
    let score = 0;
    const correctAnswers = {};
    questions.forEach(question => {
      const isCorrect = userAnswers[question.id] === question.correctAnswer;
      if (isCorrect) score++;
      correctAnswers[question.id] = question.correctAnswer;
    });
    return { score, correctAnswers };
  };

  return (
    <>
      <GlobalStyle />
      <QuizContainer>
        <QuizContent>
          <AnimatePresence mode="wait">
            {!quizCompleted ? (
              <QuestionSection
                key="question"
                initial={{ x: '100%', opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: '-100%', opacity: 0 }}
                transition={{ type: 'spring', stiffness: 300, damping: 30 }}
              >
                <QuizTitle>{t(quiz.title)}</QuizTitle>
                <ProgressBar 
                  initial={{ width: 0 }}
                  animate={{ width: `${((currentQuestionIndex + 1) / quiz.questions.length) * 100}%` }}
                  transition={{ duration: 0.5 }}
                />
                <QuestionText>{t(quiz.questions[currentQuestionIndex].text)}</QuestionText>
                <OptionsContainer>
                  {quiz.questions[currentQuestionIndex].options.map((option, index) => (
                    <OptionButton
                      key={index}
                      onClick={() => handleAnswer(option)}
                      whileHover={{ scale: 1.05, boxShadow: '0 5px 15px rgba(0,0,0,0.2)' }}
                      whileTap={{ scale: 0.95 }}
                    >
                      {t(option)}
                    </OptionButton>
                  ))}
                </OptionsContainer>
              </QuestionSection>
            ) : (
              <ResultsSection
                key="results"
                initial={{ y: '50%', opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: '-50%', opacity: 0 }}
                transition={{ type: 'spring', stiffness: 300, damping: 30 }}
              >
                <ResultsTitle>{t('Quiz Results')}</ResultsTitle>
                <ResultsContent>
                  {results ? (
                    <>
                      {quiz.questions.map((question, index) => (
                        <ResultItem key={question.id}>
                          <ResultQuestion>{t(question.text)}</ResultQuestion>
                          <ResultAnswer correct={results.correctAnswers[question.id] === answers[question.id]}>
                            {t('Your answer')}: {t(answers[question.id])}
                          </ResultAnswer>
                          <ResultAnswer correct>
                            {t('Correct answer')}: {t(results.correctAnswers[question.id])}
                          </ResultAnswer>
                        </ResultItem>
                      ))}
                      <TotalScore
                        initial={{ scale: 0 }}
                        animate={{ scale: 1 }}
                        transition={{ type: 'spring', stiffness: 500, damping: 30, delay: 0.5 }}
                      >
                        {t('Total Score')}: {results.score} {t('out of')} {quiz.questions.length}
                      </TotalScore>
                    </>
                  ) : (
                    <LoadingText>{t('Calculating results...')}</LoadingText>
                  )}
                </ResultsContent>
              </ResultsSection>
            )}
          </AnimatePresence>
        </QuizContent>
      </QuizContainer>
    </>
  );
};

const QuizContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #2c3e50;
  color: #ecf0f1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const QuizContent = styled.div`
  width: 100%;
  max-width: 600px;
  height: 100%;
  max-height: 800px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (min-width: 768px) {
    padding: 2rem;
  }
`;

const QuizTitle = styled(motion.h1)`
  font-size: 1.8rem;
  margin-bottom: 1rem;
  text-align: center;
  font-weight: bold;
  color: #3498db;
  @media (min-width: 768px) {
    font-size: 2.2rem;
    margin-bottom: 1.5rem;
  }
`;

const ProgressBar = styled(motion.div)`
  height: 6px;
  background-color: #3498db;
  margin-bottom: 1rem;
  border-radius: 3px;
`;

const QuestionSection = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

const QuestionText = styled(motion.h2)`
  font-size: 1.3rem;
  margin-bottom: 1rem;
  text-align: center;
  font-weight: bold;
  @media (min-width: 768px) {
    font-size: 1.6rem;
    margin-bottom: 1.5rem;
  }
`;

const OptionsContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  width: 100%;
`;

const OptionButton = styled(motion.button)`
  background-color: #34495e;
  border: none;
  border-radius: 10px;
  padding: 0.8rem;
  font-size: 1rem;
  color: #ecf0f1;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #2980b9;
  }
`;

const ResultsSection = styled(motion.div)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ResultsTitle = styled(motion.h2)`
  font-size: 1.8rem;
  margin-bottom: 1rem;
  text-align: center;
  color: #3498db;
  @media (min-width: 768px) {
    font-size: 2.2rem;
    margin-bottom: 1.5rem;
  }
`;

const ResultsContent = styled.div`
  flex: 1;
  overflow-y: auto;
  padding-right: 0.5rem;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #2c3e50;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #3498db;
    border-radius: 3px;
  }
`;

const ResultItem = styled(motion.div)`
  margin-bottom: 1rem;
  padding: 0.8rem;
  background-color: #34495e;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const ResultQuestion = styled.p`
  font-size: 1rem;
  margin-bottom: 0.4rem;
  font-weight: bold;
  @media (min-width: 768px) {
    font-size: 1.1rem;
  }
`;

const ResultAnswer = styled.p`
  font-size: 0.9rem;
  margin-bottom: 0.2rem;
  color: ${props => props.correct ? '#2ecc71' : '#e74c3c'};
  @media (min-width: 768px) {
    font-size: 1rem;
  }
`;

const TotalScore = styled(motion.p)`
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-top: 1rem;
  color: #3498db;
  @media (min-width: 768px) {
    font-size: 1.8rem;
    margin-top: 1.5rem;
  }
`;

const LoadingText = styled(motion.p)`
  font-size: 1.2rem;
  text-align: center;
  @media (min-width: 768px) {
    font-size: 1.4rem;
  }
`;

export default Quiz;