import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import Menu from './components/Menu';
import Presentation from './components/Presentation';
import Admin from './components/Admin';
import { PresentationProvider } from './contexts/PresentationContext';
import VideoBackgroundSlide from './components/VideoBackgroundSlide';
import Rating from './components/Rating';
import Quiz from './components/Quiz';
import WebEmbed from './components/WebEmbed';
import ImageGenerator from './components/ImageGeneration';
import AITimeline from './components/AITimeline';
import Safety from './components/Safety/SafetyCalls';
import AIWorkshopPresentation from './components/AIWorkshopPresentation';
import AboutMe from './components/AboutMe';
import WhatIsAI from './components/WhatIsAI';
import RealFakeSlider from './components/RealFakeSlider';
import GeneralPromptGeneration from './components/GeneralPromptGeneration';
import IdeogramGeneration from './components/IdeogramGeneration';
import TheGood from './components/TheGood';
import TheBad from './components/TheBad';
import WhatDoYouSee from './components/WhatDoYouSee';
import VisualMastery from './components/VisualMastery';
import TipsTricks from './components/GeneralTips';
import Kling from './components/Kling';
import DreamMachine from './components/DreamMachine';
import PromptGame from './components/PromptGame';
const App = () => {
  const tamsCourseSlides = [
    {
      type: 'custom',
      component: (
        <VideoBackgroundSlide
          videoSrc="./intro.mp4"
        />
      ),
      group: 'INTRO SLIDE',
      title: 'ARRIVAL INTRO'
    },
    {
      type: 'custom',
      component: (
        <Safety
        />
      ),  
      group: 'SAFETY',
      title: 'SAFETY'
    },
    {
      type: 'custom',
      component: <AITimeline />,
      group: 'AI TIMELINE SLIDE',
      title: 'AI Timeline Interactive'
    },
    {
      type: 'text',
      title: 'TEXT SLIDE',
      subtitle: 'Content',
      group: 'TEXT SLIDE',
      content: <p>CONTENT HERE</p>
    },
    {
      type: 'image',
      title: 'IMAGE SLIDESHOW',
      subtitle: 'Introduction to AI',
      group: 'IMAGE SLIDE',
      images: ['url1', 'url2', 'url3']
    },
 
    {
      type: 'custom',
      component: (
        <Rating />
      ),
      group: 'RATING SLIDE',
      title: 'RATING'
    },
    // {
    //   type: 'custom',
    //   component: (
    //     <Quiz
    //       quiz={{
    //         title: "AI Knowledge Quiz",
    //         questions: [
    //           {
    //             id: 'q1',
    //             text: 'What does AI stand for?',
    //             options: [
    //               'Artificial Intelligence',
    //               'Automated Information',
    //               'Advanced Integration',
    //               'Algorithmic Iteration'
    //             ],
    //             correctAnswer: 'Artificial Intelligence'
    //           },
    //           {
    //             id: 'q2',
    //             text: 'Which of these is NOT a type of AI?',
    //             options: [
    //               'Machine Learning',
    //               'Deep Learning',
    //               'Natural Language Processing',
    //               'Quantum Computing'
    //             ],
    //             correctAnswer: 'Quantum Computing'
    //           },
    //           {
    //             id: 'q3',
    //             text: 'What is the primary goal of AI?',
    //             options: [
    //               'To replace human intelligence',
    //               'To augment human capabilities',
    //               'To create sentient machines',
    //               'To eliminate all manual labor'
    //             ],
    //             correctAnswer: 'To augment human capabilities'
    //           }
    //         ]
    //       }}
    //     />
    //   ),
    //   group: 'QUIZ SLIDE',
    //   title: 'QUIZ'
    // },
       
    {
      type: 'custom',
      component: (
        <WebEmbed url="https://fastflux.ai/" />
      ),
      group: 'IFRAME SLIDE',
      title: 'WEB EMBED'
    },
    {
      type: 'custom',
      component: (
        <ImageGenerator />
      ),
      group: 'INTRO GENERATION SLIDE',
      title: 'AI Image Generator'
    }
  
  ];










  
  const aiWorkshopSlides = [
    
    {
      
      type: 'custom',
      component: (
        <VideoBackgroundSlide
          title="AI Workshop"
          subtitle="Exploring AI Art & Creativity"
          videoSrc="./intro.mp4"
          showMuteButton={true}
        />
      ),
      group: 'Welcome',
      title: 'Welcome'
    },
    {
      type: 'custom',
      component: (
        <PromptGame />
      ),
      group: 'GAME',
      title: 'GAME'
    },
   
    {
      type: 'custom',
      component: (
        <AboutMe
        />
      ),  
      group: 'Welcome',
      title: 'Introduction'
    },
    {
      type: 'custom',
      title: 'Generate, Generate, Generate',
      subtitle: 'Introduction to AI',
      group: 'Introduction to AI',
      component: (
        <ImageGenerator />
      ),
    },
    {
      type: 'custom',
      title: 'What is AI?',
      subtitle: 'Introduction to AI',
      group: 'Introduction to AI',
      component: (
        <WhatIsAI
        />
      ),  
    },
    {
      type: 'image2',
      title: 'Real or Fake?',
      subtitle: 'Introduction to AI',
      group: 'Introduction to AI',
      images: ['https://goldpenguin.org/wp-content/uploads/2024/01/holamatopoeia_a_photorealistic_cheeseburger_white_clean_backgro_fd6d2dbf-851a-4757-bae0-d43313d64063.png', 'url2', 'url3']
    },
    {
      type: 'image',
      title: 'Evolution',
      subtitle: 'Introduction to AI',
      group: 'Introduction to AI',
      images: ['https://goldpenguin.org/wp-content/uploads/2024/01/holamatopoeia_a_photorealistic_cheeseburger_white_clean_backgro_fd6d2dbf-851a-4757-bae0-d43313d64063.png', 'url2', 'url3']
    },
    // {
    //   type: 'quiz',
    //   title: 'AI Knowledge Quiz',
    //   subtitle: 'Test Your Understanding',
    //   group: 'Introduction to AI',
    //   quiz: {
    //     questions: [
    //       {
    //         id: 'q1',
    //         text: 'What does AI stand for?',
    //         options: [
    //           'Artificial Intelligence',
    //           'Automated Information',
    //           'Advanced Integration',
    //           'Algorithmic Iteration'
    //         ]
    //       },
    //       {
    //         id: 'q2',
    //         text: 'Which of the following is NOT a type of AI?',
    //         options: [
    //           'Machine Learning',
    //           'Deep Learning',
    //           'Natural Language Processing',
    //           'Quantum Computing'
    //         ]
    //       },
    //       {
    //         id: 'q3',
    //         text: 'What is the goal of AI?',
    //         options: [
    //           'To replace human intelligence',
    //           'To augment human capabilities',
    //           'To create sentient machines',
    //           'To eliminate all manual labor'
    //         ]
    //       }
    //     ]
    //   }
    // },
    {
      type: 'custom',
      component: (
        <TheGood
        />
      ),  
      group: 'The Great Side of AI',
      title: 'The Good'
    },
    {
      type: 'custom',
      component: (
        <TheBad
        />
      ),  
      group: 'The Bad Side of AI',
      title: 'The Bad'
    },
    {
      type: 'custom',
      component: (
        <WhatDoYouSee
        />
      ),  
      group: 'Prompt Engineering',
      title: 'What do you see?'
    },
    {
      type: 'custom',
      component: (
        <TipsTricks
        />
      ),  
      group: 'Prompt Engineering',
      title: 'General Tips & Tricks'
    },
    {
      type: 'custom',
      component: (
        <VisualMastery
        />
      ),  
      group: 'Prompt Engineering',
      title: 'Visual Mastery'
    },
    {
      type: 'custom',
      title: 'Flux',
      subtitle: 'Introduction to AI',
      group: 'AI Playground | Images',
      component: (
        <GeneralPromptGeneration />
      ),
    },
    {
      type: 'custom',
      title: 'Ideogram',
      subtitle: 'Introduction to AI',
      group: 'AI Playground | Images',
      component: (
        <IdeogramGeneration />
      ),
    },

    {
      type: 'web',
      title: 'Playground',
      group: 'AI Playground | Images',
      url: 'https://playground.com/'
    },
  
    {
      type: 'custom',
      title: 'Kling',
      subtitle: 'Introduction to AI',
      group: 'AI Playground | Animating',
      component: (
        <Kling />
      ),
    },
    {
      type: 'custom',
      title: 'Dream Machine',
      subtitle: 'Introduction to AI',
      group: 'AI Playground | Animating',
      component: (
        <DreamMachine />
      ),
    }
  ];

  const teAraMatihikoSlides = [
    {
      day: 1,
      slides: [
        {
          type: 'custom',
          component: (
            <VideoBackgroundSlide
              title="The Pursuit Presents"
              subtitle="Te Ara Matihiko"
              videoSrc="./intro.mp4"
            />
          ),
          group: 'Day 1',
          title: 'Welcome'
        },
        {
          type: 'text',
          title: 'Welcome to Te Ara Matihiko',
          subtitle: 'Day 1: Introduction',
          group: 'Day 1',
          content: <p>Te Ara Matihiko is a course designed to explore digital pathways.</p>
        },
      ]
    },
    {
      day: 2,
      slides: [
        {
          type: 'text',
          title: 'Day 2: Advancing Our Digital Journey',
          subtitle: 'Building on Foundations',
          group: 'Day 2',
          content: <p>Today we'll dive deeper into digital concepts and their applications.</p>
        },
      ]
    },
  ];

  return (
    <PresentationProvider>
      <Router>
        <Switch>
          <Route exact path="/" component={Menu} />
          <Route
            path="/tams-course"
            render={(props) => (
              <Presentation {...props} slides={tamsCourseSlides} />
            )}
          />
          {/* <Route
            path="/ai-workshop"
            render={(props) => (
              <Presentation {...props} slides={aiWorkshopSlides} />
            )}
          /> */}
<Route
  path="/ai-workshop"
  render={(props) => (
    <AIWorkshopPresentation {...props} slides={aiWorkshopSlides} />
  )}
/>
          <Route
            path="/te-ara-matihiko/:day"
            render={(props) => (
              <Presentation {...props} slides={teAraMatihikoSlides} />
            )}
          />
          <Route path="/admin" component={Admin} />
        </Switch>
      </Router>
    </PresentationProvider>
  );
};

export default App;