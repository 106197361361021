import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import ImageSlider from './ImageSlider';
import YouTubeEmbed from './YouTubeEmbed';
import WebEmbed from './WebEmbed';
import IntroSlide from './IntroSlide';
import RealFakeSlider from './RealFakeSlider';

const colors = [
  '#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A',
  '#98D8C8', '#F67280', '#C06C84', '#6C5B7B',
  '#355C7D', '#F8B195', '#F67280', '#C06C84'
];

const API_URL = 'https://findhayden.online/server';
const POLLING_INTERVAL = 2000;
const ENABLE_DEBUG = true; // Toggle this to enable/disable on-screen debugging

const Presentation = ({ slides = [], isAdmin = false, adminId = null }) => {
  const [showIntro, setShowIntro] = useState(true);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [maxSlideReached, setMaxSlideReached] = useState(0);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isControlPanelVisible, setIsControlPanelVisible] = useState(false);
  const [isTopControlPanelVisible, setIsTopControlPanelVisible] = useState(false);
  const [youtubeState, setYoutubeState] = useState({
    videoId: null,
    state: 'paused',
    time: 0,
    volume: 100
  });
  const [isController, setIsController] = useState(false);
  const [users, setUsers] = useState([]);
  const sidebarRef = useRef(null);
  const controlPanelRef = useRef(null);
  const topControlPanelRef = useRef(null);
  const weekRef = useRef(null);
  const courseRef = useRef(null);
  const userIdRef = useRef(null);
  const lastUpdateRef = useRef(Date.now());

  const logToPage = (message) => {
    if (ENABLE_DEBUG) {
      const logContainer = document.getElementById('debugOverlay');
      if (logContainer) {
        const logEntry = document.createElement('div');
        logEntry.textContent = message;
        logContainer.appendChild(logEntry);
      }
    }
  };

  const pollForUpdates = useCallback(async () => {
    try {
      logToPage('Polling for updates...');
      const response = await fetch(`${API_URL}/state?course=${courseRef.current}&week=${weekRef.current}&lastUpdate=${lastUpdateRef.current}`);
      const data = await response.json();
      logToPage(`Received data: ${JSON.stringify(data)}`);
      
      if (data.currentSlide !== undefined) {
        logToPage(`Updating slide to: ${data.currentSlide}`);
        setCurrentSlide(data.currentSlide);
        setMaxSlideReached(prevMax => Math.max(prevMax, data.currentSlide));
      }
      if (data.youtubeState) {
        setYoutubeState(data.youtubeState);
      }
      if (data.users) {
        setUsers(data.users);
      }
      lastUpdateRef.current = Date.now();
    } catch (error) {
      console.error('Polling error:', error);
      logToPage(`Polling error: ${error}`);
    }
  }, []);
  
  useEffect(() => {
    const pathParts = window.location.pathname.split('/');
    const course = pathParts[pathParts.length - 1];
    const urlParams = new URLSearchParams(window.location.search);
    const week = urlParams.get('week');
    const isControl = urlParams.get('control') === 'true';

    weekRef.current = week;
    courseRef.current = course;

    const joinUser = async () => {
      try {
        const response = await fetch(`${API_URL}/user/join`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ course, week, isAdmin, adminId, isControl })
        });
        const data = await response.json();
        userIdRef.current = data.userId;
        logToPage(`User joined: ${data.userId}`);
        await pollForUpdates();
      } catch (error) {
        console.error('Error joining user:', error);
        logToPage(`Error joining user: ${error}`);
      }
    };

    joinUser();
    setIsController(isControl || isAdmin);
    setCurrentSlide(0);
    setMaxSlideReached(isControl || isAdmin ? slides.length - 1 : 0);

    const pollInterval = setInterval(pollForUpdates, POLLING_INTERVAL);

    return () => {
      clearInterval(pollInterval);
      leaveUser();
    };
  }, [isAdmin, adminId, slides.length, pollForUpdates]);

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (e.clientX < 50 && e.clientY < window.innerHeight / 2) {
        setIsSidebarOpen(true);
      } else if (!sidebarRef.current?.contains(e.target)) {
        setIsSidebarOpen(false);
      }

      setIsTopControlPanelVisible(e.clientY < 50);
      setIsControlPanelVisible(e.clientY > window.innerHeight - 50);
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);

  const leaveUser = async () => {
    if (userIdRef.current !== null) {
      try {
        await fetch(`${API_URL}/user/leave`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ userId: userIdRef.current })
        });
        logToPage(`User left: ${userIdRef.current}`);
      } catch (error) {
        console.error('Error leaving user:', error);
        logToPage(`Error leaving user: ${error}`);
      }
    }
  };

  const changeSlide = async (direction) => {
    const newSlide = Math.max(0, Math.min(currentSlide + direction, slides.length - 1));
    logToPage(`Attempting to change slide to: ${newSlide}`);
    
    if (isController) {
      try {
        const response = await fetch(`${API_URL}/slide/change`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ 
            course: courseRef.current, 
            week: weekRef.current, 
            slideIndex: newSlide,
            userId: userIdRef.current
          })
        });
        
        const data = await response.json();
        
        if (response.ok && data.success) {
          logToPage(`Slide change successful. Server confirmed slide: ${data.currentSlide}`);
          setCurrentSlide(data.currentSlide);
          setMaxSlideReached(prevMax => Math.max(prevMax, data.currentSlide));
        } else {
          console.error('Failed to change slide:', data.error);
          logToPage(`Failed to change slide: ${data.error}`);
        }
      } catch (error) {
        console.error('Error changing slide:', error);
        logToPage(`Error changing slide: ${error.message}`);
      }
    } else if (newSlide <= maxSlideReached) {
      setCurrentSlide(newSlide);
    }
  };
  
  const jumpToSlide = async (index) => {
    if (isController) {
      try {
        const response = await fetch(`${API_URL}/slide/change`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ 
            course: courseRef.current, 
            week: weekRef.current, 
            slideIndex: index,
            userId: userIdRef.current
          })
        });
        
        if (response.ok) {
          setCurrentSlide(index);
          setMaxSlideReached(prevMax => Math.max(prevMax, index));
        } else {
          console.error('Failed to jump to slide');
          logToPage('Failed to jump to slide');
        }
      } catch (error) {
        console.error('Error jumping to slide:', error);
        logToPage(`Error jumping to slide: ${error}`);
      }
    } else if (index <= maxSlideReached) {
      setCurrentSlide(index);
    }
  };

  const handleYoutubeStateChange = async (newState) => {
    if (isController) {
      try {
        const response = await fetch(`${API_URL}/youtube/sync`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ 
            course: courseRef.current, 
            week: weekRef.current, 
            state: newState,
            userId: userIdRef.current
          })
        });
        
        if (response.ok) {
          setYoutubeState(newState);
        } else {
          console.error('Failed to sync YouTube state');
          logToPage('Failed to sync YouTube state');
        }
      } catch (error) {
        console.error('Error syncing YouTube state:', error);
        logToPage(`Error syncing YouTube state: ${error}`);
      }
    }
  };

  const handleIntroComplete = () => {
    setShowIntro(false);
  };

  const slideVariants = {
    enter: (direction) => ({
      x: direction > 0 ? '100%' : '-100%',
      opacity: 0,
    }),
    center: {
      x: 0,
      opacity: 1,
    },
    exit: (direction) => ({
      x: direction < 0 ? '100%' : '-100%',
      opacity: 0,
    }),
  };

  const renderSlideContent = (slide) => {
    if (!slide) return null;
    
    switch (slide.type) {
      case 'custom':
        return slide.component;
      case 'text':
        return <TextContent>{slide.content}</TextContent>;
      case 'image':
        return <ImageSlider images={slide.images} />;
      case 'image2':
        return <ImageSlider images={slide.images} />;
      case 'youtube':
        return (
          <YouTubeEmbed
            videoId={slide.videoId}
            isController={isController}
            youtubeState={youtubeState}
            onStateChange={handleYoutubeStateChange}
          />
        );
      case 'web':
        return <WebEmbed url={slide.url} />;
      default:
        return null;
    }
  };

  const visibleSlides = isController ? slides : slides.slice(0, maxSlideReached + 1);

  const groupedSlides = visibleSlides.reduce((acc, slide, index) => {
    if (!acc[slide.group]) {
      acc[slide.group] = [];
    }
    acc[slide.group].push({ ...slide, index });
    return acc;
  }, {});

  const getCurrentGroup = () => {
    return slides[currentSlide]?.group || '';
  };

  if (slides.length === 0) {
    return <EmptyState>No slides available</EmptyState>;
  }

  return (
    <FullscreenContainer>
      <AnimatePresence>
        {showIntro && <IntroSlide onIntroComplete={handleIntroComplete} />}
      </AnimatePresence>
      <AnimatePresence initial={false} custom={currentSlide}>
        <Slide
          key={currentSlide}
          custom={currentSlide}
          variants={slideVariants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: 'spring', stiffness: 300, damping: 30 },
            opacity: { duration: 0.2 },
          }}
          style={{ 
            backgroundColor: slides[currentSlide]?.type !== 'custom' 
              ? colors[currentSlide % colors.length]
              : 'transparent'
          }}
        >
          <SlideContent>
            {slides[currentSlide] && renderSlideContent(slides[currentSlide])}
          </SlideContent>
        </Slide>
      </AnimatePresence>

      <InteractionOverlay />

      <Sidebar
        ref={sidebarRef}
        initial={{ x: '-100%' }}
        animate={{ x: isSidebarOpen ? 0 : '-100%' }}
        transition={{ type: 'spring', stiffness: 300, damping: 30 }}
      >
        {Object.entries(groupedSlides).map(([group, groupSlides]) => (
          <div key={group}>
            <GroupTitle>{group}</GroupTitle>
            {groupSlides.map((slide) => (
              <SidebarItem
                key={slide.index}
                onClick={() => jumpToSlide(slide.index)}
                $isActive={slide.index === currentSlide}
              >
                {slide.title}
              </SidebarItem>
            ))}
            <Divider />
          </div>
        ))}
      </Sidebar>

      {isController && (
        <>
          <AnimatePresence>
            {isTopControlPanelVisible && (
              <TopControlPanel
                ref={topControlPanelRef}
                initial={{ y: -100, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -100, opacity: 0 }}
                transition={{ type: 'spring', stiffness: 300, damping: 30 }}
              >
                <GroupTitle>{getCurrentGroup()}</GroupTitle>
                <ControlButtonContainer>
                  <ControlButton onClick={() => changeSlide(-1)} disabled={currentSlide === 0}>
                    Previous
                  </ControlButton>
                  <ControlButton onClick={() => changeSlide(1)} disabled={currentSlide === slides.length - 1}>
                    Next
                  </ControlButton>
                </ControlButtonContainer>
              </TopControlPanel>
            )}
          </AnimatePresence>

          <AnimatePresence>
            {isControlPanelVisible && (
              <ControlPanel
                ref={controlPanelRef}
                initial={{ y: 100, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: 100, opacity: 0 }}
                transition={{ type: 'spring', stiffness: 300, damping: 30 }}
              >
                <ControlButtonContainer>
                  <ControlButton onClick={() => changeSlide(-1)} disabled={currentSlide === 0}>
                    Previous
                  </ControlButton>
                  <ControlButton onClick={() => changeSlide(1)} disabled={currentSlide === slides.length - 1}>
                    Next
                  </ControlButton>
                </ControlButtonContainer>
              </ControlPanel>
            )}
          </AnimatePresence>
        </>
      )}

      {ENABLE_DEBUG && <DebugOverlay id="debugOverlay" />}
    </FullscreenContainer>
  );
};

const FullscreenContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
`;

const Slide = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SlideContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TextContent = styled.div`
  text-align: center;
  color: white;
  padding: 20px;
  max-width: 80%;
  font-size: 1.5rem;
  line-height: 1.6;
`;

const InteractionOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 50px;
  height: 50%;
  z-index: 1001;
`;

const Sidebar = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px;
  overflow-y: auto;
  z-index: 1000;
`;

const GroupTitle = styled.h3`
  color: white;
  font-size: 1.2rem;
  margin-top: 20px;
  margin-bottom: 10px;
`;

const SidebarItem = styled.div`
  color: white;
  padding: 10px;
  margin-bottom: 5px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  background-color: ${props => props.$isActive ? 'rgba(255, 255, 255, 0.2)' : 'transparent'};

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const Divider = styled.hr`
  border: 0;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 10px 0;
`;

const ControlPanel = styled(motion.div)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const TopControlPanel = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const ControlButtonContainer = styled.div`
  display: flex;
  gap: 20px;
`;

const ControlButton = styled.button`
  padding: 10px 20px;
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  border: 2px solid white;
  border-radius: 30px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  transition: all 0.3s ease;

  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const EmptyState = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 2rem;
  color: #333;
`;

const DebugOverlay = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  font-size: 12px;
  padding: 5px;
  z-index: 10000;
`;

export default Presentation;
