import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ArrowRight, BookOpen, Brain, CheckCircle2, DoorClosed, DoorOpen, Key, User, Info, Star, BarChart, HelpCircle, AlertTriangle } from 'lucide-react';
import axios from 'axios';
import styled from 'styled-components';

const OPENAI_API_KEY = process.env.REACT_APP_LLM_API_KEY;

const levels = [
  {
    id: 1,
    title: "The Pattern Recognition Door",
    description: "Understand how LLMs recognize and complete patterns in text.",
    hint: "LLMs excel at recognizing common patterns in language. Try providing the start of a well-known phrase or sequence.",
    icon: Brain,
    promptTemplate: "{userInput}",
    examplePrompts: [
      "To be or not to be, that is the",
      "1, 2, 3, 4,",
      "Roses are red, violets are",
    ],
    evaluationLogic: (response, userInput) => {
      const patterns = {
        "To be or not to be, that is the": "question",
        "1, 2, 3, 4,": "5",
        "Roses are red, violets are": "blue",
      };
      return Object.entries(patterns).some(([start, end]) => 
        userInput.toLowerCase().startsWith(start.toLowerCase()) && 
        response.toLowerCase().includes(end.toLowerCase())
      );
    },
    feedback: (success) => success 
      ? "Great job! LLMs are trained on vast amounts of text data, allowing them to recognize and complete common patterns in language. This demonstrates their ability to understand context and predict likely continuations."
      : "The LLM didn't complete the pattern as expected. Try using a more common or well-known phrase. Remember, LLMs work based on patterns they've seen in their training data.",
    explanation: "LLMs like GPT-4 are trained on massive datasets of text. This training allows them to recognize patterns in language and predict likely continuations. However, this also means they're limited to patterns present in their training data and may struggle with very recent or niche information.",
  },
  {
    id: 2,
    title: "The Contextual Understanding Door",
    description: "Explore how LLMs use context to generate appropriate responses.",
    hint: "Provide a scenario or context, then ask a question that requires understanding that context.",
    icon: BookOpen,
    promptTemplate: "{userInput}",
    examplePrompts: [
      "In a world where water is scarce and precious, what might be used as currency?",
      "If humans had evolved to live underwater, how might our cities look?",
      "In a society where aging has been cured, what new problems might arise?",
    ],
    evaluationLogic: (response) => {
      return response.length > 50 && 
             (response.toLowerCase().includes("water") || 
              response.toLowerCase().includes("underwater") || 
              response.toLowerCase().includes("aging"));
    },
    feedback: (success) => success 
      ? "Excellent! The LLM demonstrated its ability to understand and reason within the given context. It can generate creative and logical responses based on the scenario you provided."
      : "The response didn't fully engage with the context provided. Try giving a more detailed scenario or asking a more specific question within that context.",
    explanation: "LLMs can understand and use context to generate appropriate responses. They can take a given scenario and extrapolate logical consequences or possibilities. This showcases their ability to 'reason' within a given framework, although they don't truly understand in the way humans do.",
  },
  {
    id: 3,
    title: "The Logical Reasoning Door",
    description: "Test the LLM's ability to follow logical steps and perform simple calculations.",
    hint: "Present a problem that requires step-by-step reasoning or a simple calculation.",
    icon: Key,
    promptTemplate: "Solve this problem step by step: {userInput}",
    examplePrompts: [
      "If a train travels 120 km in 2 hours, what is its average speed in km/h?",
      "A recipe calls for 2 cups of flour for 4 servings. How much flour is needed for 10 servings?",
      "If today is Tuesday, what day will it be 100 days from now?",
    ],
    evaluationLogic: (response) => {
      return response.toLowerCase().includes("step") &&
             response.split(' ').length > 30 &&
             (response.includes("60") || response.includes("5") || response.includes("tuesday"));
    },
    feedback: (success) => success 
      ? "Well done! The LLM successfully broke down the problem and solved it step by step. This demonstrates its ability to follow logical processes, although it's important to note that it doesn't truly 'understand' the problem in a human sense."
      : "The LLM didn't provide a clear step-by-step solution. Try rephrasing your problem or explicitly asking for steps in the solution.",
    explanation: "LLMs can follow logical steps and perform simple calculations when prompted correctly. However, they don't have a true understanding of math or logic. They're pattern-matching based on their training data, which can sometimes lead to errors, especially with more complex problems.",
  },
  {
    id: 4,
    title: "The Knowledge Cutoff Door",
    description: "Understand the limitations of LLMs regarding current events and recent information.",
    hint: "Ask about a recent event or piece of information that occurred after the LLM's training cutoff date.",
    icon: AlertTriangle,
    promptTemplate: "What can you tell me about {userInput}? If you're not sure or if this happened after your training data, please say so.",
    examplePrompts: [
      "the winner of the 2026 FIFA World Cup",
      "the most recent Mars rover mission",
      "the latest breakthrough in quantum computing",
    ],
    evaluationLogic: (response) => {
      return response.toLowerCase().includes("don't know") || 
             response.toLowerCase().includes("not sure") || 
             response.toLowerCase().includes("after my training") ||
             response.toLowerCase().includes("cutoff");
    },
    feedback: (success) => success 
      ? "Great job! You've identified a limitation of LLMs. They have a knowledge cutoff date and cannot provide accurate information about events that occurred after their training data was collected."
      : "The LLM seems to have provided information without acknowledging its potential limitations. Try asking about a more recent or future event to demonstrate the knowledge cutoff.",
    explanation: "LLMs have a 'knowledge cutoff' - a date beyond which they don't have accurate information. This is because they are trained on a fixed dataset and don't have real-time access to current information. Always be aware of this limitation when asking about recent events or developments.",
  },
  {
    id: 5,
    title: "The Bias and Limitation Awareness Door",
    description: "Explore how LLMs can reflect biases present in their training data and how they handle tasks they're not well-suited for.",
    hint: "Present a scenario that might reveal biases, or ask the LLM to perform a task it's not designed for.",
    icon: Brain,
    promptTemplate: "{userInput}",
    examplePrompts: [
      "Describe a typical doctor. Then, reflect on any potential biases in your description.",
      "Write a short story, then list the emotions of each character. Can you really understand these emotions?",
      "What's the airspeed velocity of an unladen swallow? Explain your reasoning and any limitations in answering this.",
    ],
    evaluationLogic: (response) => {
      return (response.toLowerCase().includes("bias") || 
              response.toLowerCase().includes("limitation") || 
              response.toLowerCase().includes("unable to")) &&
              response.split(' ').length > 50;
    },
    feedback: (success) => success 
      ? "Excellent work! You've prompted the LLM to reflect on its own limitations or potential biases. This kind of self-awareness is crucial when working with AI systems."
      : "The LLM didn't clearly acknowledge its limitations or potential biases. Try asking more directly about the limitations of its response or knowledge.",
    explanation: "LLMs can reflect biases present in their training data, and they have significant limitations. They don't have true understanding or emotions, can't access external information, and can't perform tasks that require real-world interaction. Being aware of these limitations is crucial for responsible AI use.",
  },
];

const AppContainer = styled.div`
  min-height: 100vh;
  background: linear-gradient(to bottom right, #8b5cf6, #3b82f6);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  font-family: 'Arial', sans-serif;
`;

const GameContainer = styled(motion.div)`
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  width: 100%;
  max-width: 48rem;
  overflow-y: auto;
  max-height: 90vh;
`;

const Title = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1.5rem;
  color: #5b21b6;
`;

const ScoreBoard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const ScoreItem = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
`;

const LevelInfo = styled(motion.div)`
  margin-bottom: 1.5rem;
`;

const LevelTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
`;

const LevelDescription = styled.p`
  color: #4b5563;
  margin-bottom: 0.5rem;
`;

const LevelHint = styled.p`
  font-size: 0.875rem;
  color: #6b7280;
  font-style: italic;
`;

const DoorContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
  position: relative;
`;

const CharacterIcon = styled(motion.div)`
  position: absolute;
  top: 0;
  right: 0;
  background-color: white;
  border-radius: 9999px;
  padding: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Form = styled.form`
  margin-bottom: 1rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  margin-bottom: 0.5rem;
`;

const Button = styled(motion.button)`
  width: 100%;
  background-color: #8b5cf6;
  color: white;
  padding: 0.5rem;
  border-radius: 0.375rem;
  font-weight: 600;
  cursor: pointer;
  
  &:hover {
    background-color: #7c3aed;
  }
  
  &:disabled {
    background-color: #c4b5fd;
    cursor: not-allowed;
  }
`;

const MessageContainer = styled(motion.div)`
  margin-bottom: 1rem;
  padding: 0.75rem;
  border-radius: 0.375rem;
`;

const ErrorMessage = styled(MessageContainer)`
  background-color: #fee2e2;
  border: 1px solid #fecaca;
`;

const ResponseMessage = styled(MessageContainer)`
  background-color: #d1fae5;
  border: 1px solid #a7f3d0;
`;

const FeedbackMessage = styled(MessageContainer)`
  background-color: #dbeafe;
  border: 1px solid #bfdbfe;
`;

const ExplanationMessage = styled(MessageContainer)`
  background-color: #fffbeb;
  border: 1px solid #fef3c7;
`;

const TokenInfo = styled.div`
  margin-top: 1rem;
  font-size: 0.875rem;
  color: #6b7280;
`;

const TutorialButton = styled(motion.button)`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  background-color: white;
  padding: 0.5rem;
  border-radius: 9999px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  
  &:hover {
    background-color: #f3f4f6;
  }
`;

const ExamplesContainer = styled.div`
  margin-bottom: 1rem;
`;

const ExamplePrompt = styled.div`
  background-color: #f3f4f6;
  border-radius: 0.375rem;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #e5e7eb;
  }
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 0.5rem;
  background-color: #e5e7eb;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  overflow: hidden;
`;

const ProgressFill = styled.div`
  height: 100%;
  background-color: #8b5cf6;
  transition: width 0.3s ease;
`;

const DoorToKnowledge = () => {
  const [currentLevel, setCurrentLevel] = useState(0);
  const [input, setInput] = useState('');
  const [response, setResponse] = useState('');
  const [feedback, setFeedback] = useState('');
  const [explanation, setExplanation] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [score, setScore] = useState(0);
  const [showTutorial, setShowTutorial] = useState(true);
  const [tokenUsage, setTokenUsage] = useState(0);

  useEffect(() => {
    if (!OPENAI_API_KEY) {
      setError("OpenAI API key is missing. Please set the REACT_APP_OPENAI_API_KEY environment variable.");
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    const level = levels[currentLevel];
    let prompt = level.promptTemplate.replace('{userInput}', input);

    try {
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: "gpt-4",
          messages: [{ role: "user", content: prompt }],
          max_tokens: 150,
        },
        {
          headers: {
            'Authorization': `Bearer ${OPENAI_API_KEY}`,
            'Content-Type': 'application/json',
          },
        }
      );

      const aiResponse = response.data.choices[0].message.content.trim();
      setResponse(aiResponse);
      setTokenUsage(prevUsage => prevUsage + response.data.usage.total_tokens);

      const success = level.evaluationLogic(aiResponse, input);
      setIsOpen(success);
      setFeedback(level.feedback(success));
      setExplanation(level.explanation);

      if (success) {
        setScore(prevScore => prevScore + 100);
      }
    } catch (error) {
      setError(error.response?.data?.error?.message || "An error occurred while processing your request.");
    } finally {
      setIsLoading(false);
    }
  };

  const nextLevel = () => {
    if (currentLevel < levels.length - 1) {
      setCurrentLevel(currentLevel + 1);
      setInput('');
      setResponse('');
      setFeedback('');
      setExplanation('');
      setIsOpen(false);
      setError(null);
    }
  };

  const renderTutorial = () => (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
    >
      <motion.div 
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 50, opacity: 0 }}
        className="bg-white p-6 rounded-lg max-w-lg"
      >
        <h2 className="text-2xl font-bold mb-4">Welcome to The Door to Knowledge!</h2>
        <p className="mb-4">In this game, you'll learn about the capabilities and limitations of large language models (LLMs) like GPT-4.</p>
        <ul className="list-disc pl-5 mb-4">
          <li>Each level explores a different aspect of LLM functionality.</li>
          <li>Craft your prompts to test and understand LLM behavior.</li>
          <li>Learn from the feedback and explanations provided.</li>
          <li>Progress through the levels to gain a comprehensive understanding of LLMs.</li>
        </ul>
        <Button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => setShowTutorial(false)}
        >
          Start Learning
        </Button>
      </motion.div>
    </motion.div>
  );

  const Icon = levels[currentLevel].icon;

  return (
    <AppContainer>
      <AnimatePresence>
        {showTutorial && renderTutorial()}
      </AnimatePresence>
      <GameContainer
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Title>The Door to Knowledge: Understanding LLMs</Title>
        <ScoreBoard>
          <ScoreItem>
            <Star color="#fbbf24" size={20} style={{ marginRight: '0.5rem' }} />
            <span>Score: {score}</span>
          </ScoreItem>
          <ScoreItem>
            <BarChart color="#3b82f6" size={20} style={{ marginRight: '0.5rem' }} />
            <span>Level: {currentLevel + 1}/{levels.length}</span>
          </ScoreItem>
        </ScoreBoard>
        <ProgressBar>
          <ProgressFill style={{ width: `${((currentLevel + 1) / levels.length) * 100}%` }} />
        </ProgressBar>
        <LevelInfo
          key={currentLevel}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          <LevelTitle>
            <Icon size={24} style={{ marginRight: '0.5rem' }} />
            {levels[currentLevel].title}
          </LevelTitle>
          <LevelDescription>{levels[currentLevel].description}</LevelDescription>
          <LevelHint>Hint: {levels[currentLevel].hint}</LevelHint>
        </LevelInfo>
        <DoorContainer
          animate={{ rotateY: isOpen ? 180 : 0 }}
          transition={{ duration: 0.5 }}
        >
          {isOpen ? <DoorOpen size={80} color="#10b981" /> : <DoorClosed size={80} color="#ef4444" />}
          <CharacterIcon
            animate={{ x: isOpen ? 20 : 0 }}
            transition={{ duration: 0.5 }}
          >
            <User size={24} color="#8b5cf6" />
          </CharacterIcon>
        </DoorContainer>
        <ExamplesContainer>
          <h3 className="font-bold mb-2">Example prompts:</h3>
          {levels[currentLevel].examplePrompts.map((prompt, index) => (
            <ExamplePrompt key={index} onClick={() => setInput(prompt)}>
              {prompt}
            </ExamplePrompt>
          ))}
        </ExamplesContainer>
        <Form onSubmit={handleSubmit}>
          <Input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Enter your prompt here..."
            disabled={isLoading}
          />
          <Button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            type="submit" 
            disabled={isLoading}
          >
            {isLoading ? "Processing..." : "Submit"}
          </Button>
        </Form>
        <AnimatePresence>
          {error && (
            <ErrorMessage
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
            >
              <p>{error}</p>
            </ErrorMessage>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {response && (
            <ResponseMessage
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
            >
              <p>{response}</p>
            </ResponseMessage>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {feedback && (
            <FeedbackMessage
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
            >
              <p>{feedback}</p>
            </FeedbackMessage>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {explanation && (
            <ExplanationMessage
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
            >
              <h3 className="font-bold mb-2">Explanation:</h3>
              <p>{explanation}</p>
            </ExplanationMessage>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {isOpen && currentLevel < levels.length - 1 && (
            <Button
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={nextLevel}
            >
              Next Level <ArrowRight size={20} style={{ marginLeft: '0.5rem' }} />
            </Button>
          )}
        </AnimatePresence>
        {isOpen && currentLevel === levels.length - 1 && (
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            style={{ textAlign: 'center' }}
          >
            <CheckCircle2 color="#10b981" size={40} style={{ display: 'inline-block', marginBottom: '0.5rem' }} />
            <p style={{ fontSize: '1.25rem', fontWeight: 600, color: '#059669' }}>
              Congratulations! You've completed all levels!
            </p>
            <p style={{ marginTop: '1rem', color: '#4b5563' }}>
              You've learned about:
              <ul style={{ listStyleType: 'disc', textAlign: 'left', marginTop: '0.5rem', paddingLeft: '1.25rem' }}>
                <li>Pattern recognition in LLMs</li>
                <li>Contextual understanding</li>
                <li>Logical reasoning capabilities</li>
                <li>Knowledge limitations</li>
                <li>Bias and limitations in LLMs</li>
              </ul>
            </p>
            <div style={{ marginTop: '1rem' }}>
              <p style={{ fontWeight: 'bold' }}>Final Score: {score}</p>
              <p>Total Tokens Used: {tokenUsage}</p>
            </div>
          </motion.div>
        )}
        <TokenInfo>
          <p>Token Usage: {tokenUsage}</p>
          <p>Rate Limit: {tokenUsage > 100000 ? "Exceeded" : "Within Limit"}</p>
        </TokenInfo>
      </GameContainer>
      <TutorialButton
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        onClick={() => setShowTutorial(true)}
      >
        <Info size={24} color="#8b5cf6" />
      </TutorialButton>
    </AppContainer>
  );
};

export default DoorToKnowledge;